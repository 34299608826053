import React, { forwardRef, useImperativeHandle, memo, useState, useCallback, useMemo, Fragment } from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

import COMMON from "common";
import ERRORS from "common/errors";
import classNames from "common/class-names";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import getVehicleBodyListing from "services/get-vehicleBody-listing";

const AppRegenerateModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	//prettier-ignore
	const [regenerateForm, setRegenerateForm] = useState({ sumInsured: "", nvicCode: "", vehicleBody: "", minimumMarketValue: "", maximumMarketValue: "", minimumAgreedValue: "", maximumAgreedValue: "", allianzVariant: "", allianzCoverage: "", reconCar: "No", initialAllianzVariant: "", initialAllianzCoverage: "" });
	const nvicOptions = useMemo(() => props?.nvicDropdown?.map((o) => ({ label: o.nvic, value: o.nvic, ...o })), [props?.nvicDropdown]);
	const hasAllianz = useMemo(() => props?.hasAllianz || false, [props?.hasAllianz]);
	const allianzAgreeOptions = useMemo(() => props?.allianzAgree?.map((o) => ({ label: o.modelDescription, value: o.nvic, ...o })), [props?.allianzAgree]);
	const allianzMarketOptions = useMemo(() => props?.allianzMarket?.map((o) => ({ label: o.modelDescription, value: o.nvic, ...o })), [props?.allianzMarket]);

	//prettier-ignore
	const initialValues = useMemo(() => {

	let payload = {
		marketCoverage: true,
		agreedCoverage: false,
		sumInsured: "",
		nvicCode: "",
		vehicleBody: "",
		minimumMarketValue: 0,
		maximumMarketValue: 0,
		minimumAgreedValue: 0,
		maximumAgreedValue: 0,
		allianzVariant: "",
		allianzCoverage: "",
		reconCar: "No",
		initialAllianzVariant: "",
		initialAllianzCoverage: "",
		initialValueType: ""
	}

	if (regenerateForm) payload = regenerateForm

	return payload;
	}, [regenerateForm]);

	//prettier-ignore
	const regenerateValidationSchema = useMemo(() => {
		let schema = { sumInsured: yup.string().required().test("validate sum insured range", "Enter the amount within the market value range.", function (value) {
					const current = parseFloat(value?.replace(",", ""));
					const minimumValue = this.parent.marketCoverage ? this.parent.minimumMarketValue : this.parent.minimumAgreedValue;
					const maximumValue = this.parent.marketCoverage ? this.parent.maximumMarketValue : this.parent.maximumAgreedValue;
					return current >= minimumValue && current <= maximumValue;
				}),
			allianzVariant: yup.string(),
			allianzCoverage: yup.number(),
			reconCar: yup.string(),
		};

		if (hasAllianz) {
			schema.allianzVariant = yup.string().required(ERRORS.REQUIRED);
			schema.allianzCoverage = yup.number().moreThan(0, ERRORS.REQUIRED);
			schema.reconCar = yup.string().required(ERRORS.REQUIRED);
		}

		return schema;
	}, [hasAllianz]);

	//prettier-ignore
	const formik = useFormik({
		enableReinitialize: true,
		validateOnBlur: true,
		initialValues,
		validationSchema: yup.object(regenerateValidationSchema),
		onSubmit: (values) => {
			const payload = { ...values, requireRegenerate: true };
			props.onHandleSubmit(payload);
			formik.setSubmitting(false);
		},
	});

	const valueType = formik.values.marketCoverage ? "market" : "agreed";
	const minimumValue = formik.values.marketCoverage ? formik.values.minimumMarketValue : formik.values.minimumAgreedValue;
	const maximumValue = formik.values.marketCoverage ? formik.values.maximumMarketValue : formik.values.maximumAgreedValue;
	const allianzVariantOption = formik.values.agreedCoverage ? allianzAgreeOptions : allianzMarketOptions;

	//prettier-ignore
	const allianzCoverageOptions = useMemo(() => {
		let coverageOptions = [];

		const selectedVariant = allianzVariantOption.find((variant) => variant.nvic === formik.values.allianzVariant);

		if (selectedVariant) {
			coverageOptions = [{ label: formatCurrency(selectedVariant.maxMarketValue), value: selectedVariant.maxMarketValue }];

			if (selectedVariant.fixedSumInsuredList) {
				coverageOptions = selectedVariant.fixedSumInsuredList.map((value) => ({ label: formatCurrency(value), value }));
			};
		};

		return coverageOptions;
	}, [formik.values.allianzVariant, allianzVariantOption]);

	//prettier-ignore
	const toggleClassName = useCallback((isActive) => {
		return classNames({
			"toggle-section__radio": true,
			"toggle-section__radio--active": isActive,
		});
	}, []);

	//prettier-ignore
	const onHandleCoverageChecked = useCallback((coverageType) => {
		const isMarketCoverage = coverageType === "marketCoverage";

		formik.setFieldValue("marketCoverage", isMarketCoverage);

		formik.setFieldValue("agreedCoverage", !isMarketCoverage);

		if (formik.values.initialValueType === coverageType) {
			formik.setFieldValue("allianzVariant", formik.values.initialAllianzVariant);

			formik.setFieldValue("allianzCoverage", formik.values.initialAllianzCoverage);
		} else {
			formik.setFieldValue("allianzVariant", "");

			formik.setFieldValue("allianzCoverage", "");
		}

		formik.setFieldValue("reconCar", "No");
	}, [formik]);

	//prettier-ignore
	const onHandleUpdate = useCallback((event, nvicList) => {
		const selectedNvic = nvicList?.find((o) => o.nvic === event.target.value);

		formik.setFieldValue("nvicCode", event.target.value);

		formik.setFieldValue("minimumMarketValue", selectedNvic.minMarketValue);

		formik.setFieldValue("maximumMarketValue", selectedNvic.maxMarketValue);

		formik.setFieldValue("minimumAgreedValue", selectedNvic?.minAgreedValue);

		formik.setFieldValue("maximumAgreedValue", selectedNvic?.maxAgreedValue);
	}, [formik]);

	//prettier-ignore
	const onHandleUpdateVehicleBody = useCallback((event) => {
		formik.setFieldValue("vehicleBody", event.target.value);
	}, [formik]);

	//prettier-ignore
	const onHandleUpdateAllianzVariant = useCallback((event) => {
		const variantNvic = event.target.value;
		
		const selectedVariant = allianzVariantOption.find(variant => variant.nvic === variantNvic);

		formik.setFieldValue("allianzVariant", variantNvic);

		formik.setFieldValue("allianzVariantDisplay", selectedVariant.modelDescription);

		formik.setFieldValue("allianzCoverage", "");
	}, [allianzVariantOption, formik]);

	//prettier-ignore
	const onHandleUpdateAllianzCoverage = useCallback((event) => {
		formik.setFieldValue("allianzCoverage", event.target.value);
	}, [formik]);

	//prettier-ignore
	const onHandleUpdateReconCar = useCallback((event) => {
		formik.setFieldValue("reconCar", event.target.value);
	}, [formik]);

	const onHandleKeyDown = (event) => {
		const cursorPosition = event.target.selectionEnd;
		const value = event.target.value;

		if (cursorPosition !== value.length) {
			event.target.selectionEnd = value.length;
			event.target.selectionStart = value.length;
			event.preventDefault();
		}

		if (event.key.length === 1 && !event.key.match(/\d/i)) {
			event.preventDefault();
		}
	};

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		const selectedNvic = props?.nvicDropdown?.find((o) => o.nvic === values?.nvicCodeDisplay);
		
		setRegenerateForm({ ...values, modelDescription: selectedNvic?.modelDescription, initialAllianzVariant: values.allianzVariant, initialAllianzCoverage: values.allianzCoverage, initialValueType: values.marketCoverage ? "marketCoverage" : "agreedCoverage" });

		formik.setErrors({});
		setVisible(true);
	}, [setRegenerateForm, formik, props?.nvicDropdown]);

	//prettier-ignore
	const onHandleDismiss = useCallback((nvicList) => {
		setVisible(false);

		const selectedNvic = nvicList?.find((o) => o.nvic === formik.values?.nvicCodeDisplay);

		formik.resetForm({ values: { ...formik.initialValues, nvicCode: formik.values?.nvicCodeDisplay, sumInsured: formik.values?.sumInsuredDisplay, vehicleBody: formik.values?.vehicleBodyDisplay, minimumMarketValue: selectedNvic?.minMarketValue, maximumMarketValue: selectedNvic?.maxMarketValue, minimumAgreedValue: selectedNvic?.minAgreedValue, maximumAgreedValue: selectedNvic?.maxAgreedValue, allianzVariant: formik.values?.allianzVariant, allianzCoverage: formik.values?.allianzCoverage, reconCar: formik.values?.reconCar, marketCoverage: formik.values.marketCoverage, agreedCoverage: formik.values.agreedCoverage } });
	}, [formik]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal keepMounted aria-labelledby="regenerate-modal__title" aria-describedby="regenerate-modal__text" open={visible} closeAfterTransition onClose={() => onHandleDismiss(props?.nvicDropdown)}>
			<Fade in={visible}>
				<div className="app-regenerate-modal">
					<div className="regenerate-modal">
						<div className="regenerate-modal__container">
							<form className="regenerate-modal__form" onSubmit={formik.handleSubmit}>
								<div className="toggle-section">
									<div className="toggle-section__wrapper">
										{/* prettier-ignore */}
										<button className={toggleClassName(formik.values?.marketCoverage)} type="button" disabled={props.disabled} name="marketCoverage" checked={formik.values.marketCoverage} onClick={() => onHandleCoverageChecked("marketCoverage")} />

										<p className="toggle-section__text">Market Value</p>
									</div>

									<div className="toggle-section__wrapper">
										{/* prettier-ignore */}
										<button className={toggleClassName(formik.values?.agreedCoverage)} type="button" disabled={props.disabled} name="agreedCoverage" checked={formik.values.agreedCoverage} onClick={() => onHandleCoverageChecked("agreedCoverage")} />

										<p className="toggle-section__text">Agreed Value</p>
									</div>
								</div>

								<div className="regenerate-modal__divider" />

								<div className="regenerate-modal__description">{`Your vehicle's ${valueType} value range is RM${formatCurrency(minimumValue)} - RM${formatCurrency(maximumValue)}`}</div>

								{/* prettier-ignore */}
								<AppInput required type="text" name="sumInsured" label="Sum Insured (RM)" placeholder="00.00" onKeyDown={onHandleKeyDown} onFormat={formatCurrencyPattern} onChange={formik.handleChange} value={formik.values?.sumInsured} touched={formik.touched?.sumInsured} error={formik.errors?.sumInsured} disabled={formik.isSubmitting} />

								{/* prettier-ignore */}
								<AppSelectInput required type="text" name="nvicCode" label="NVIC Code" placeholder="Please Select" options={nvicOptions} value={formik.values?.nvicCode} error={formik.errors?.nvicCode} touched={formik.touched?.nvicCode} onChange={(event) => onHandleUpdate(event, props?.nvicDropdown)} disabled={formik.isSubmitting} />

								{/* prettier-ignore */}
								<AppSelectInput required type="text" name="vehicleBody" label="Vehicle Body" placeholder="Please Select" loadOptions={getVehicleBodyListing} value={formik.values?.vehicleBody} error={formik.errors?.vehicleBody} touched={formik.touched?.vehicleBody} onChange={onHandleUpdateVehicleBody} disabled={formik.isSubmitting} />

								{hasAllianz && (
									<Fragment>
										{/* prettier-ignore */}
										<div className="regenerate-modal__sub-description">Agreed Value is not applicable for reconditioned vehicles (imported). If you select Agreed Value with your reconditioned car, claims settlement will be at risk.</div>

										{/* prettier-ignore */}
										<div className="regenerate-modal__additional-title">Additional Information<span className="regenerate-modal__additional-info">(Applicable for Allianz Insurance only)</span></div>

										{/* prettier-ignore */}
										<AppSelectInput type="text" required name="allianzVariant" label="Allianz Variant" placeholder="Please Select" options={allianzVariantOption} value={formik.values?.allianzVariant} error={formik.errors?.allianzVariant} touched={formik.touched?.allianzVariant} onChange={onHandleUpdateAllianzVariant} disabled={formik.isSubmitting} />

										{/* prettier-ignore */}
										<AppSelectInput type="text" required name="allianzCoverage" label="Allianz Coverage (RM)" placeholder="Allianz Coverage" options={allianzCoverageOptions} value={formik.values?.allianzCoverage} error={formik.errors?.allianzCoverage} touched={formik.touched?.allianzCoverage} onChange={onHandleUpdateAllianzCoverage} disabled={formik.isSubmitting || !formik.values.allianzVariant} />

										{/* prettier-ignore */}
										<AppSelectInput type="text" required name="reconCar" label="Recon Car" placeholder="Please Select" options={COMMON.DROPDOWNS.YES_NO} value={formik.values?.reconCar} error={formik.errors?.reconCar} touched={formik.touched?.reconCar} onChange={onHandleUpdateReconCar} disabled={formik.isSubmitting || !formik.values.allianzVariant || formik.values.agreedCoverage} searchable={false} />
									</Fragment>
								)}

								<div className="regenerate-modal__button-container">
									<AppButton type="button" label="Cancel" outline onClick={() => onHandleDismiss(props?.nvicDropdown)} />
									<AppButton type="submit" label="Regenerate Quotation" disabled={!formik.dirty} />
								</div>
							</form>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default memo(forwardRef(AppRegenerateModal));

AppRegenerateModal.propTypes = {
	onHandleSubmit: PropTypes.func,
	nvicDropdown: PropTypes.array,
	allianzMarket: PropTypes.array,
	allianzAgree: PropTypes.array,
};
